import React, {useCallback, useState, forwardRef} from 'react';
import Loader from '../loader';

const Input = forwardRef(
  (
    {
      id,
      label,
      error,
      errorClass,
      especialErro,
      containerClass,
      className,
      onChangeText,
      onBlur,
      formatText,
      loading,
      maxLength,
      disabled,
      placeholder,
      value = '',
      type = 'text',
      inputMode = 'text',
      formControlStyle = {},
      nextTabRef,
    },
    ref,
  ) => {
    const [v, setValue] = useState('');

    const onChange = useCallback(
      ({target}) => {
        let temp = target.value;
        if (typeof formatText === 'function') temp = formatText(temp);
        setValue(temp);
        if (typeof onChangeText === 'function') onChangeText(temp);
      },
      [onChangeText, formatText],
    );

    const nextTab = (e) => {
      if (e.key === 'Tab') {
        e.preventDefault(); // WTF

        nextTabRef.current.focus();
      }
    };

    return (
      <div className={'c_input position-relative ' + containerClass}>
        {label && <div className=" py-1 label">{label}</div>}
        <div className={`form-control input_alt ${especialErro}`} style={formControlStyle} disabled={disabled}>
          <input
            id={id}
            ref={ref}
            onKeyDown={(e) => nextTab(e)}
            tabIndex={-1}
            type={type}
            onBlur={onBlur}
            disabled={disabled}
            onChange={onChange}
            inputMode={inputMode}
            maxLength={maxLength}
            placeholder={placeholder}
            value={value}
            className={'px-0 form-control clear ' + className}
          />
          {loading && <Loader className="loading" size="sm" />}
        </div>
        {error && <small className={'text-danger ' + errorClass}>{error}</small>}
      </div>
    );
  },
);

export default Input;
